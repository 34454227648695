//直接获取当前日期，并格式化（YYYY-mm-dd）
export function dateFormat(fmt, date) {
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    }; 
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        }
    }   
    return fmt;
}

/**
 * 
 * 获取任意时间
 * 参数：AddDayCount，传的值代表据当天日期的天数，例如-1代表昨天，-2代表两天前
 * 推荐使用这个函数
 * 
 */
export function getAnyDate(AddDayCount) { 

    var dd = new Date();
 
    dd.setDate(dd.getDate()+AddDayCount);//获取AddDayCount天后的日期
 
    var y = dd.getFullYear(); 
 
    var m = (dd.getMonth()+1)<10?"0"+(dd.getMonth()+1):(dd.getMonth()+1);//获取当前月份的日期，不足10补0
 
    var d = dd.getDate()<10?"0"+dd.getDate():dd.getDate();//获取当前几号，不足10补0
  
    return y+"-"+m+"-"+d; 
}


// 使用示例
let date = new Date();
console.log( dateFormat("YYYY-mm-dd HH:MM:SS",date) );